<template>
  <div class="mb-7">
    <section v-if="item.type_of_question != 'identification' && item.type_of_question != 'essay'">
      <v-radio-group
        dense
        v-model="answer.answer"
        readonly
        class="mx-3"
        hide-details
      >
        <v-row align="start" justify="center" dense  no-gutters>
          <v-col cols="12" lg="10">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="6"
                v-for="(choice, i) in item.assessment_answer.choices" :key="choice.id"
                class="d-flex align-center secondary-1--text f14"
              >
                <v-radio class="pt-2" :value="Object.keys(choice)[0]" hide-details dense/>
                <div class="roboto f14 fw500 secondary-1--text text-capitalize">{{choice[`${Object.keys(choice)[0]}`]}}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-radio-group>
    </section>
    <v-text-field v-else-if="item.type_of_question == 'identification'"
        dense
        outlined
        class="mx-3 general-custom-field f14 roboto fw500 secondary-1--text"
        v-model="answer.answer"
        placeholder="Type your answer here"
        hide-details
        readonly
    />
    <v-container>
      <v-container readonly v-if="item.type_of_question == 'essay' && answer.answer" v-html="answer.answer ? $dompurifier(answer.answer) : ''" class="essay-answer rounded-lg" outlined></v-container>
    </v-container>
    <section v-if="item.require_support_answer" class="mx-3">
      <FormLabel :label="`Justification:`" />
      <v-textarea 
        outlined
        dense
        auto-grow
        rows="3"
        readonly
        hide-details
        :value="answer.support_answer"
        class="general-custom-field f14 roboto fw500 secondary-1--text"
        />
    </section>
    <v-container v-if="item.require_file_attachment">
        <Document :file="answer.file" />
        <!-- <v-btn
            :href="answer.file" color="primary poppins" class="btn btn-success text-capitalize" download>Download</v-btn> -->
    </v-container>
    <section v-if="checked === 1">
      <v-sheet class="mx-3 mt-3 pa-2 rounded" color="dark-1" v-if="item.type_of_question == 'multiple_choice' || item.type_of_question == 'true_or_false'">
        <div class="poppins f12 fw400 secondary-2--text">Correct Answer</div>
        <div class="d-flex align-center my-1">
          <v-radio color="success" class="" />
          <div class="roboto f12 fw500 secondary-1--text">
            {{ getCorrectAnswer(assessment_question.assessment_answer.choices, assessment_question.assessment_answer.correct_answer) }}
          </div>
        </div>
        <div class="roboto f14 fw500 secondary-1--text font-italic mt-2">{{
          assessment_question.assessment_answer.choices[assessment_question.assessment_answer.correct_answer.replace('choice_','')].feedback
        }}</div>
      </v-sheet>
      <v-sheet class="mx-3 mt-3 pa-2 rounded" color="dark-1" v-if="item.type_of_question == 'identification'">
        <div class="poppins f12 fw400 secondary-2--text">Possible Answers: </div>
        <div class="roboto f12 fw500 secondary-1--text">
          <span v-for="(item, i) in assessment_question.assessment_answer.choices" :key="i">
            {{ item[`choice_${i}`] }}{{i !== assessment_question.assessment_answer.choices.length - 1 ? ',':''}} 
          </span>
        </div>
      </v-sheet>
      <v-container v-if="item.type_of_question == 'essay' || item.require_file_attachment" class="mt-5">
        <div class="poppins fw600 f13 secondary-4--text font-italic"> INSTRUCTOR'S FEEDBACK: </div>
        <v-textarea 
          outlined
          dense
          auto-grow
          rows="5"
          readonly
          :value="answer.question_answer_feedback ? answer.question_answer_feedback.comment : null"
          class="poppins f14 fw400 rounded my-2"
          />
      </v-container>
    </section>
  </div>
</template>

<style>
.essay-answer {
  background-color: rgba(130, 130, 130, 20%) !important;
}
</style>

<script>
import Document from "../../../constants/material/Document.vue";

export default {
  props: ['answer' , 'i', 'item', 'errorFile', 'assessment_question', 'checked'],
  components: {
    Document
  },
  data: () => ({
  }),
  methods: {
    getCorrectAnswer(choices, correct_answer) {
      const correctAnswerObj = choices.find(item => item[correct_answer] !== undefined);
      const correctAnswerValue = correctAnswerObj ? correctAnswerObj[correct_answer] : null;
      return correctAnswerValue;
    }
    
  },
  computed: {
  }
}
</script>