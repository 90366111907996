<template>
  <section ref="materialTimeline" v-if="!inMeeting" :style="{'--bgColor': getPrimary}">
    <v-expansion-panels class="" accordion flat v-model="expand_material" multiple>
      <v-expansion-panel
        v-for="(item, i) in modules"
        :key="item.uuid"
      >
        <v-expansion-panel-header color="secondary-5"
        class="m-0 p-0 mat_expansion_panel ">
          <template v-slot:default="{ open }">
            <v-container class="ma-0 pa-0 d-flex flex-column description_div">
              <v-row no-gutters>
                <v-col cols="9">
                  <section class="description_div roboto f14 fw500 secondary-1--text">
                    <b>{{item.title ? item.title : 'Untitled' }}</b> 
                  </section>
                </v-col>
                <v-col cols="3">
                  <section class="d-flex flex-row">
                    <v-icon size="18" class="float-right" v-if="item.valid_until !== 'anytime' && locked(item.valid_until)">{{item.valid_until !== 'anytime' && locked(item.valid_until) ? 'mdi-lock-outline' : 'mdi-lock-open-outline'}}</v-icon>
                    <v-badge
                      v-else
                      bordered
                      :color="isModuleCompleted(item) ? 'success' : 'warning'"
                      size='10'
                      inline
                      class="ml-auto"
                      
                    />
                  </section>
                </v-col>
              </v-row>
              
              <div class="f10 secondary-2--text mt-1 mb-3 " v-if="item.valid_until !== 'anytime' && locked(item.valid_until)">
                This module will be accessible on {{ $dateFormat.mmDDyy(item.valid_until) }}
              </div>
              <section v-if="item.description" class="description_div f12 secondary-2--text mt-1" v-html="item.description ? $dompurifier(item.description) : ''"></section>
            </v-container>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-timeline description_div ma-0 pa-0">
          <v-container v-if="!item.data" class="roboto secondary-2--text f12 fw600 pl-10">
            No modules available
          </v-container>
          <div v-else>
            <div
              v-for="(data, j) in item.data" 
              :key="data.uuid"
            >
           
              <v-list-item dense 
                class="d-flex align-center"
                :class="[data.is=='topic'?'':'pl-8', course_content[current_index].uuid === data.uuid ? 'warning-3' : '', ]"
                :active = "course_content[current_index].uuid === data.uuid || data.student_done_status"
                :disabled="item.valid_until !== 'anytime' && locked(item.valid_until) || data.student_done_status || isButtonDisabled"
                @click="$emit('goTo', course_content.findIndex((items) => items.uuid === data.uuid))"
              >
                <v-icon small color="primary" v-if="data.learning_material != null">
                  {{ data.learning_material.file_type == 'youtube'
                    ? 'mdi-youtube'
                    : data.learning_material.file_type == 'pdf'
                    ? 'mdi-file-pdf-box'
                    : data.learning_material.file_type == 'mp4' 
                    ? 'mdi-file-video-outline'
                    : data.learning_material.file_type == 'scorm_file'
                    ? 'mdi-archive-outline'
                    : 'mdi-folder-question-outline'}}
                    
                </v-icon>
                <v-icon small color="primary" v-else>mdi-eye-off-outline</v-icon>
                <v-list-item-title class="roboto secondary-1--text ml-2"
                  :class="data.is=='topic'? 'f13 fw500':'f12 fw500'">
                  {{data.title}}
                </v-list-item-title>
                <v-badge
                  bordered
                  :class="(!data.student_done_status && data.uuid != course_content[current_index].uuid) && 'thin-border'"
                  :color="data.student_done_status ? 'success' : data.uuid == course_content[current_index].uuid ? 'warning' : ''"
                  size='10'
                  inline
                  :disabled="isButtonDisabled"
                  :icon="data.student_done_status && 'mdi-check'"
                >
                </v-badge>
              </v-list-item>
              <div
                v-if="data.topic_has_assessments" 
                v-for="(assessments, k) in data.topic_has_assessments">
                <v-list-item
                  dense 
                  link
                  class="d-flex align-center pl-8"
                  :disabled="item.valid_until !== 'anytime' && locked(item.valid_until) || isButtonDisabled "
                  @click="$emit('goTo', course_content.findIndex((items) => items.uuid === assessments.uuid))"
                  :class="[course_content[current_index].uuid === assessments.uuid ? 'warning-3' : '']">
                  <v-icon small color="primary">
                    mdi-note-edit-outline
                  </v-icon>
                  <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                    <div class="f9 secondary-2--text">
                      Assessment
                    </div>
                    {{assessments.title}}
                    <div class="f10 secondary-2--text" v-if="assessments.assessment_score.score">
                      Submitted on: {{$dateFormat.mmDDyy(assessments.assessment_score.created_at)}}
                    </div>
                    <div class="f10 secondary-2--text" v-else-if="assessments.due_date && JSON.parse(assessments.due_date).end !== ''">
                      Due Date: {{assessments.due_date ? $dateFormat.mmDDyy(JSON.parse(assessments.due_date).end) : ''}}
                    </div>
                  </v-list-item-title>
                  <v-spacer></v-spacer>
                  <v-icon small color="primary">
                    {{ lockedAssessment(JSON.parse(assessments.due_date)) ? 'mdi-lock-outline' : 'mdi-lock-open-outline'}}
                  </v-icon>
                  <v-badge
                    bordered
                    :class="(!assessments.assessment_score.score && assessments.uuid != course_content[current_index].uuid) && 'thin-border'"
                    :color="assessments.assessment_score.score ? 'success' : course_content[current_index].uuid === assessments.uuid ? 'warning' : ''"
                    size='10'
                    inline
                    :disabled="isButtonDisabled"
                    :icon="assessments.assessment_score.score && 'mdi-check'"
                  >
                  </v-badge>
                </v-list-item>
              </div>

              <div
                v-if="data.sub_topic_has_assessments" 
                v-for="(assessments, k) in data.sub_topic_has_assessments"
                :key="assessments.uuid">
                  <v-list-item
                    dense 
                    link
                    class="d-flex align-center pl-13"
                    :disabled="item.valid_until !== 'anytime' && locked(item.valid_until) || isButtonDisabled"
                    @click="$emit('goTo', course_content.findIndex((items) => items.uuid === assessments.uuid))"
                    :class="[course_content[current_index].uuid === assessments.uuid ? 'warning-3' : '']">
                    <v-icon small color="primary">
                      mdi-note-edit-outline
                    </v-icon>
                    <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                      <div class="f9 secondary-2--text">
                        Assessment
                      </div>
                      {{assessments.title}}
                      <div class="f10 secondary-2--text" v-if="assessments.assessment_score.score">
                        Submitted on: {{$dateFormat.mmDDyy(assessments.assessment_score.created_at)}}
                      </div>
                      <div class="f10 secondary-2--text" v-else-if="assessments.due_date && JSON.parse(assessments.due_date).end !== ''">
                        Due Date: {{assessments.due_date ? $dateFormat.mmDDyy(JSON.parse(assessments.due_date).end) : ''}}
                      </div>
                    </v-list-item-title>
                    <v-spacer></v-spacer>
                    <v-icon small color="primary">
                      {{ lockedAssessment(JSON.parse(assessments.due_date)) ? 'mdi-lock-outline' : 'mdi-lock-open-outline'}}
                    </v-icon>
                    <v-badge
                      bordered
                      :class="(!assessments.assessment_score.score && assessments.uuid != course_content[current_index].uuid) && 'thin-border'"
                      :color="assessments.assessment_score.score ? 'success' : course_content[current_index].uuid === assessments.uuid ? 'warning' : ''"
                      size='10'
                      inline
                      :disabled="isButtonDisabled"
                      :icon="assessments.assessment_score.score && 'mdi-check'"
                    >
                    </v-badge>
                  </v-list-item>
                </div>
            </div>
            <!-- FOR MODULE EVALUATION -->
            <div v-if="item.module_evaluations.length > 0"
                v-for="evaluation in item.module_evaluations"
                :key="evaluation.uuid">
              <v-list-item dense 
                class="d-flex align-center"
                :disabled="item.valid_until !== 'anytime' && locked(item.valid_until) || isButtonDisabled"
                @click="$emit('goTo', course_content.findIndex((items) => items.uuid === evaluation.uuid))"
                :class="[course_content[current_index].uuid === evaluation.uuid ? 'primary-1' : '']"
              >
                <v-icon small color="primary">
                  mdi-file-document-edit-outline
                </v-icon>
                <v-list-item-title class="roboto secondary-1--text ml-2 f13 fw500">
                  <div class="f9 secondary-2--text">
                    Module Evaluation
                  </div>
                  {{ evaluation.title }}
                </v-list-item-title>
                <v-badge
                  bordered
                  :class="(!evaluation.student_module_evaluations_count > 0 && evaluation.uuid != course_content[current_index].uuid) && 'thin-border'"
                  :color="evaluation.student_module_evaluations_count > 0 ? 'success' : course_content[current_index].uuid === evaluation.uuid ? 'warning' : ''"
                  size='10'
                  inline
                  :disabled="isButtonDisabled"
                  :icon="evaluation.student_module_evaluations_count > 0 && 'mdi-check'"
                >
                </v-badge>
              </v-list-item>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="course.course_evaluations.length > 0">
        <v-expansion-panel-header color="secondary-5"
        class="m-0 p-0 mat_expansion_panel ">
          <template v-slot:default="{ open }">
            <v-container class="ma-0 pa-0 d-flex flex-column description_div">
              <section class="d-flex flex-row align-center description_div justify-space-between roboto f14 fw500 secondary-1--text ">
                <section class="description_div">
                  <b>Course Evaluation</b> 
                </section>
              </section>
            </v-container>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-timeline description_div">
          <v-container v-if="course.course_evaluations.length === 0" class="roboto secondary-2--text f12 fw600 pl-10">
            No course evaluation available
          </v-container>
          <div v-else>
            <div
              v-for="evaluation in course.course_evaluations"
              :key="evaluation.uuid">
              <v-list-item dense 
                class="d-flex align-center timeline-content"
                @click="$emit('goTo', course_content.findIndex((items) => items.uuid === evaluation.uuid))"
                :class="[course_content[current_index].uuid === evaluation.uuid ? 'warning-3' : '']"
              >
                <v-icon small color="primary">
                  mdi-file-document-edit-outline
                </v-icon>
                <v-list-item-title class="roboto secondary-1--text ml-2 f12 fw500">
                  {{evaluation.title}}
                </v-list-item-title>
                <v-badge
                  bordered
                  :class="(!evaluation.student_course_evaluations_count > 0 && evaluation.uuid != course_content[current_index].uuid) && 'thin-border'"
                  :color="evaluation.student_course_evaluations_count > 0 ? 'success' : course_content[current_index].uuid === evaluation.uuid ? 'warning' : ''"
                  size='10'
                  inline
                  :disabled="isButtonDisabled"
                  :icon="evaluation.student_course_evaluations_count > 0 && 'mdi-check'"
                >
                </v-badge>
              </v-list-item>
              </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<style scoped>
.v-expansion-panel-header.mat_expansion_panel {
  align-items: start !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.description_div {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.expansion-timeline .v-expansion-panel-content__wrap {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.topic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.subtopic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.assessments.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}

.v-badge.thin-border .v-badge__badge::after {
  border-color: #BDBDBD !important;
  border-width: 1px !important;
  margin: 1px !important;
}

</style>

<style scoped>
p {
  margin-bottom: 5px !important;
}

</style>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: ['course', 'modules', 'current_index', 'course_content', 'inMeeting'],
  data: () => ({
    expand_material: [],
  }),
  computed: {
    ...mapState({
      customization: (state) => state.customization
    }),
    isButtonDisabled() {
      const { available_until, prerequisite, sort } = this.course;
      let today = new Date().toISOString().slice(0, 10);

      // Check if the end date has passed
      if (available_until && available_until.end_date) {
        const endDate = new Date(available_until.end_date).toISOString().slice(0, 10);
        if (today > endDate) {
          return true;
        }
      }

      // Check if prerequisites are not completed
      if (!prerequisite?.course_prerequisite?.completed_course) {
        return true;
      }

      // Check for sorting logic
      if (sort === 1) {
        return false;
      }

      return false;
    },

    // disableButtonNext(){
    //   if(this.course_content[this.current_index + 1].is == 'topic' || this.course_content[this.current_index + 1] == 'sub-topic') {
    //     return false
    //   } else {
    //     return this.course_content[this.current_index + 1].due_date ? this.course_content[this.current_index].student_done_status ? false : true : true
    //   }
      
    // },

    getPrimary(){
      return this.customization.hasOwnProperty('primary_buttons_color') ? this.customization.primary_buttons_color : this.$vuetify.theme.currentTheme.primary
    },
  },

  
  mounted() {
    if(this.$route.name === 'User Course Details') {
      this.modules.forEach((item, i) => {
        this.expand_material.push(i)
      })
      if(this.course.course_evaluations.length > 0) {
        this.expand_material.push(this.expand_material.length)
      }
    } else {
      if(this.course_content.length > 0) {
        this.expand_material.push(this.course_content[this.current_index].expanded)
      }
    }
    
  },

  methods: {
    ...mapActions('usr', ['showNextContentAction']),

    localset(index) {
      localStorage.setItem('material_panel', index)
    },

    isModuleCompleted(_module){
      for(let topic of _module.topics){
        if(!topic.student_done_status) {
          return false
        } else {
          for(let topic_assessment of topic.topic_has_assessments) {
            if(!topic_assessment.assessment_score.score) {
              return false
            }
          }

          for(let sub_topic of topic.sub_topics) {
            if(!sub_topic.student_done_status) {
              return false
            } else {
              for(let sub_topic_assessment of sub_topic.sub_topic_has_assessments) {
                if(!sub_topic_assessment.assessment_score.score) {
                  return false
                }
              }
            }
          }
        }
      } 
      if(_module.topics.length === 0) return false
      return true
    },

    locked(item) {
      let today = new Date().toISOString().split("T")[0];
 
      if(today > item.valid_until) return false
      return true
    },

    lockedAssessment(due_date) {
      if(due_date) {
        let start = new Date(due_date.start)
        let due = new Date(due_date.end)
        let today = Date.now();
        if(due_date.end === '' && due_date.start !== '') {
          if(start <= today)return false
          else return true
        } else if(due_date.start === '' && due_date.end !== '') {
          if(due >= today) return false
          else return true
        } else if(due_date.end === '' && due_date.start === '') {
          return true
        }else if(due_date.end !== '' && due_date.start !== '') {
          if(start <= today && due >= today) {
            return false 
          } else return true
        }
      } 
      return true
    },

    view(item) {
      this.$router.push({
        name: 'User Assessment',
        params: { uuid: item.uuid }
      })
    },
  },
  
}
</script>