<template>
  <section>
    <v-sheet class="fade" color="transparent" :height="lists?'': '80vh'">
      <v-row align="center" justify="center" class="fill-height">
        <v-col cols="12" lg="9">
          <v-btn text @click="backAssessment" class=" f14 poppins primary--text">
            <v-icon small>mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <v-sheet id="form" class="roboto custom-border border pa-10 mb-5">
            <div class="d-flex flex-row">
              <v-spacer/>
              <div class="d-flex flex-wrap text-right ml-auto px-3 align-self-end">
                <div class="my-2" :class="($vuetify.breakpoint.mdAndDown || $vuetify.breakpoint.mobile) ? 'mr-10' : ''">
                  <div class="poppins f11 secondary-2--text fw500">DUE DATE</div>
                  <div class="roboto f15 fw500">{{JSON.parse(data.due_date).end ? $dateFormat.mmDDyy(JSON.parse(data.due_date).end) : '-'}}</div>
                </div>
                <v-divider vertical class="mx-5" v-if="!$vuetify.breakpoint.mdAndDown || !$vuetify.breakpoint.mobile" />
                <div class="my-2" :class="($vuetify.breakpoint.mdAndDown || $vuetify.breakpoint.mobile) ? 'mr-10' : ''">
                  <div class="poppins f11 secondary-2--text fw500">DATE SUBMITTED</div>
                  <div class="roboto f15 fw500">{{$dateFormat.mmDDyy(data.assessment_score.created_at)}}</div>
                </div>
                <v-divider vertical class="mx-5"  v-if="!$vuetify.breakpoint.mdAndDown || !$vuetify.breakpoint.mobile" />
                <div class="my-2">
                  <div class="poppins f11 secondary-2--text fw500">TOTAL SCORE</div>
                  <div class="roboto f15 fw500" v-if="answers.checked">
                    {{ data.assessment_score.score % data.assessment_score.score === 0 ? parseInt(data.assessment_score.score) : data.assessment_score.score }} / {{ data.assessment_questions_sum_score }} pts
                  </div>
                </div>
              </div>
            </div>
            
            <v-divider class="mb-3"/>
            <v-row class="px-3">
              <v-col cols="12" lg="9">
                <label class="poppins f11 secondary-2--text fw500">ASSESSMENT TITLE</label>
                <h3 class="roboto fw600">
                  {{`${data.title}`}}
                </h3>
              </v-col>
              <v-col cols="12" lg="3">
                <label class="poppins f11 secondary-2--text fw500">STATUS</label>
                <v-alert dense :color="answers.checked ? '#7BC14533' : '#BDBDBD33'" class="ma-auto" :class="answers.checked ? 'success--text' : 'secondary-2--text'">
                    {{ answers.checked ? 'CHECKED' : 'PENDING' }}
                </v-alert>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet id="form" class="roboto custom-border border pa-10 mb-5">
            <div v-for="(item, i) in data.assessment_questions" :key="item.id">
                <QuizHeader :i="i" :item="item" :checked="answers.checked" :score="data.assessment_scores[0].question_answer[i].score"/>
                <ChoicesWithAnswers 
                  :assessment_question="item"
                  :checked="answers.checked"
                  :answer="answers.question_answer.find(_item => _item.assessment_question_id === item.assessment_answer.assessment_question_id)" 
                  :i="i" 
                  :item="item"
                />
                <v-divider class="my-10" v-if="i+1 != data.assessment_questions_count"/>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </section>
</template>

<script>
import OnePager from '@/components/user/assessment/OnePager.vue';
import ChoicesWithAnswers from '@/components/user/assessment/ChoicesWithAnswers.vue';
import QuizHeader from '@/components/user/assessment/QuizHeader.vue';

export default {
  props: ['data', 'answers'],

  components: {
  OnePager,
  ChoicesWithAnswers,
  QuizHeader
},

  data: () => ({
    lists: true,
    indexFile: null,
    formHasErrors: false,
    errorFile: false,
    fileTypes: /(\.jpg|\.jpeg|\.png|\.docx|\.pdf)$/i /** regex */
  }),

  mounted(){
      // console.log(this.answers)
  },

  methods: {
      backAssessment(){
          this.$emit('backSubmitted')
      },
  }
}
</script>