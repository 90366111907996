<template>
    <v-dialog v-model="dialog" max-width="500px"> <!-- Adjusted max-width -->
        <v-card class="pa-4"> <!-- Added padding -->
            <v-card-text v-if="loading" class="text-center d-flex flex-column align-center justify-center">
                <v-card-title class="poppins primary--text text-center">
                    Generating Certificate...
                </v-card-title>
                <v-card-text class="poppins secondary--text">
                    Please wait and do not close the page.
                </v-card-text>
                <v-progress-linear indeterminate color="primary" rounded height="5px"></v-progress-linear>
            </v-card-text>
            <section v-if="!loading" class="text-center d-flex flex-column align-center poppins justify-center success--text">
                <v-btn icon @click="$emit('close')" class="close-btn" dense>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title class="poppins success--text text-center">
                    Generated Certificate has been sent to your email!
                </v-card-title>
            </section>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["dialog", "loading", "error", "url"],
};
</script>

<style scoped>
.close-btn {
    align-self: flex-end;
    margin-bottom: -20px;
    margin-top: -10px;
}

.poppins {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.primary--text {
    color: #1E88E5;
}

.secondary--text {
    color: #757575;
}

.success--text {
    color: #43A047;
}

.text-center {
    text-align: center;
}

.pa-4 {
    padding: 16px;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}
</style>
