<template>
  <div class="mb-7">
    <v-radio-group
      dense
      v-if="item.type_of_question != 'identification' && item.type_of_question != 'essay'"
      v-model="answers[i].answer"
      :rules="[() => !!answers[i].answer || 'Answer is required']"
    >
      <v-row align="start" justify="center">
        <v-col cols="12" lg="10">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              v-for="(choice, i) in item.assessment_answer.choices" :key="choice.id"
              class="d-flex align-center secondary-1--text f14 my-2"
            >
              <v-radio class="pt-2" :value="Object.keys(choice)[0]"/>
              <div class="roboto f14 fw500 secondary-1--text text-capitalize">{{choice[`${Object.keys(choice)[0]}`]}}</div>
              <!-- <div class="roboto f14 fw500 secondary-1--text text-capitalize">{{choice[`choice_${i}`]}}</div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-text-field v-else-if="item.type_of_question == 'identification'"
        dense
        outlined
        class="col-12 col-lg-6 mx-7 general-custom-field f14 roboto fw500 secondary-1--text"
        v-model="answers[i].answer"
        placeholder="Type your answer here"
        hide-details
    />
    <section v-if="item.type_of_question == 'essay'" class="mx-3">
      <editor
        class="tiny"
        :api-key="mce_key"
        v-model="answers[i].answer"
        :init="{
          placeholder: 'Type your answer here',
          height: 200,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor table',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
            bullist numlist outdent indent | removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }"
      />
    </section>
    <v-textarea v-if="answers[i].is_require_support_answer"
        dense
        outlined
        auto-grow
        rows="3"
        class="col-12 col-lg-6 mx-7 general-custom-field f14 roboto fw500 secondary-1--text"
        v-model="answers[i].support_answer"
        placeholder="Support your answer"
        hide-details
    />
    <div
      v-if="item.require_file_attachment"
      class="bulk-border ma-3"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop($event, i)"
    >
      <div class="d-flex align-center justify-center">
        <div class="d-flex flex-column  align-center my-3">
          <v-icon color="rgba(63, 93, 208, 0.12)" size="32"
          >mdi-cloud-upload-outline</v-icon
          >
          <div class="secondary--text fw400">
          <a
              href="#"
              @click="$emit('findFile', i)"
              class="primary--text text-decoration-none"
              >Browse</a
          >
          or drag file here 
          </div>
          <div class="secondary--text f12">( .jpg, .jpeg, .png, .doc, .docx, .pdf, .pptx, .ppt )</div>
        </div>
      </div>
    </div>
    <div class="ma-7" v-if="item.require_file_attachment">

      <div class="d-flex align-center my-5" v-if="answers[i].file">
        <div class="secondary-3--text f13 mx-2 d-flex flex-column"> 
          {{`${answers[i].file.name}`}}
          <v-progress-linear
            color="primary"
            rounded
            value="100"
            :indeterminate="answers[i].loading" 
            v-if="answers[i].loading"
          ></v-progress-linear>
          <span class="f12 float-right">{{ `${answers[i].file.size} kb` }}</span>
        </div>
        <v-icon v-if="!answers[i].loading && answers[i].errorMessages == ''" color="success">mdi-check-circle-outline</v-icon>
      </div>
    </div>
    
    <div class="ma-7 d-flex align-center" v-if="answers[i].errorMessages">
      <v-icon color="danger-1">mdi-alert-circle-outline</v-icon>
      <div class="danger-1--text f14 roboto fw500 mx-2">
        {{answers[i].errorMessages}}
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  props: ['answers' , 'i', 'item', 'errorFile'],
  components: {
    editor: Editor,
  },
  data: () => ({
    mce_key: process.env.VUE_APP_TINYMCE_KEY
  }),
  methods: {
    dragover(e) {
      e.preventDefault();
      e.currentTarget.classList.add("bulk-border-drag");
    },
    dragleave(e) {
      e.preventDefault();
      e.currentTarget.classList.remove("bulk-border-drag");
    },

    drop(e, i) { 
      e.preventDefault();
      this.$emit('getDropFiles', { index: i, file: e.dataTransfer.files });
      e.currentTarget.classList.remove("bulk-border-drag");
    },
  }
}
</script>