<template>
  <v-card class="custom-border" height="100%">
      <v-sheet style="background-color: rgba(63, 93, 208, 0.04)" rounded class="px-3 py-10 d-flex flex-column align-center" min-height="55vh">
          <div class="f20 fw600 roboto secondary-1--text text-center">
            {{data.title}}
          </div>
          <div v-html="data.description ? $dompurifier(data.description) : ''" class="f14 fw500 roboto secondary-1--text ma-5 mx-5"></div>
          <v-sheet color="transparent" max-width="500" class="my-10 mx-auto">
            <div class="row justify-center">
              <div class="col-6 d-flex align-center fw500 f12">
                Due date: <span class="fw400 mx-2">{{JSON.parse(data.due_date) ? JSON.parse(data.due_date).end : ''}}</span>
              </div>
              <div class="col-6 fw500 f12">
                Total Points: <span class="fw400 mx-2">{{ getPoints }}</span>
              </div>
              <div class="col-6 fw500 f12">
                No of Questions: <span class="fw400 mx-2">{{data.assessment_questions_count}}</span>
              </div>
              <div class="col-6 fw500 f12">
                Time Limit: <span class="fw400 mx-2">{{ data.time_limit ? data.time_limit+' minutes' : 'none'}}</span>
              </div>
              <div class="col-6 fw500 f12">
                No of attempts done: <span class="fw400 mx-2">{{data.assessment_score.attempts }}</span>
              </div>
              <div class="col-6 fw500 f12">
                No of allowed attempts: <span class="fw400 mx-2"> {{ data.max_attempt == null ? 1 : data.max_attempt}}</span>
              </div>
              <div class="col-6 fw500 f12">
                Date accomplished: <span class="fw400 mx-2">{{ data.assessment_score.created_at && $dateFormat.mmDDyy(data.assessment_score.created_at) }}</span>
              </div>
              <div class="col-6 fw500 f12">
                Time accomplished: <span class="fw400 mx-2"> {{ data.assessment_score.created_at && $dateFormat.hhMM(data.assessment_score.created_at) }}</span>
              </div>
            </div>
          </v-sheet>
          <!-- <v-alert 
            v-if="data.max_attempt && data.assessment_score_count >= data.max_attempt"
            outlined type="error" 
            class="col-lg-8 mx-auto"
          > -->
          <v-alert 
            v-if="data.assessment_score.attempts >= (data.max_attempt == null ?  1 : data.max_attempt)"
            outlined type="error" 
            class="col-lg-8 mx-auto"
          >
            <div class="f14 roboto fw500 error--text">You have reached the maximum number of attempts</div>
          </v-alert>
          <v-alert 
            v-else-if="lockedAssessment(JSON.parse(data.due_date))"
            outlined color="error" 
            class="col-lg-8 mx-auto d-flex flex-row"
            icon="mdi-lock-outline"
          >
            <div class="f14 roboto fw500 error--text">This assessment is locked. </div>
          </v-alert>
          <v-alert 
            v-else-if="data.assessment_questions_count === 0"
            outlined color="error" 
            type="warning"
            class="col-lg-8 mx-auto d-flex flex-row"
          >
            <div class="f14 roboto fw500 error--text">There are no questions available in this assessment</div>
          </v-alert>
          <div class="text-center">
            <div class="col-12 fw600 f15" v-show="data.assessment_questions.every((question) => question.type_of_question != 'identification' && question.type_of_question != 'essay')">
              My {{ data.accepted_score ? data.accepted_score.charAt(0).toUpperCase() +  data.accepted_score.slice(1) : ""}} score: <span class=" fw600 f15 mx-2"> {{ data.assessment_score.score }} </span>
            
            </div>
            <!-- <v-btn 
              v-if="link"
              :to="{ name: 'User Assessment', params: { uuid: 123456 } }"
              class="poppins f14 fw500" 
              color="primary"
            >
              Start the Quiz
            </v-btn> -->
            <v-btn 
              class="poppins f12 fw500 mb-3" 
              color="primary"
              @click="start"
              v-if="data.assessment_questions_count > 0"
              :disabled="lockedAssessment(JSON.parse(data.due_date)) ? true :  data.assessment_score.attempts >= (data.max_attempt == null ?  1 : data.max_attempt)"
            >
              Start the Quiz
            </v-btn>
          </div>
          <div class="text-center">
            <v-btn 
              text
              class="poppins f12 fw500 text-capitalize" 
              color="primary"
              @click="viewLatest"
              v-if="data.assessment_score.attempts > 0"
            >
              View Latest Submission
            </v-btn>
          </div>
      </v-sheet>
  </v-card>
</template>

<script>
export default {
props: ['link', 'data'],
methods: {
  start() {
    this.$emit('start')    
  },
  viewLatest() {
    this.$emit('viewLatest')  
  },
  lockedAssessment(due_date) {
  if (!due_date) return true;

  const today = Date.now();
  const start = due_date.start ? new Date(due_date.start) : null;
  const due = due_date.end ? new Date(due_date.end) : null;

  if (!start && !due) return true;
  if (start && start > today) return true;
  if (due && due < today) return true;

  return false;
},


  lockAssessmentforAttempts(max_attempt, attempts) {
    if(max_attempt === null) max_attempt = 1
    if(max_attempt >= attempts) return false

    return true
  }
},

computed: {
  getPoints(){
    if(this.data.assessment_questions.every((item) => item.score )) {
      return `${this.data.assessment_questions.reduce((prev, current) => prev + parseFloat(current.score), 0)} pts`
    } else return ''
    
  }
}
}
</script>